<template>
	<div>
		<header class="header-home">
			<div class="header-home__background">
				<div class="container">
					<div class="row">
						<div class="container">
							<div class="col-6 col-md-4">
								<div class="header-home__logo"></div>
							</div>
						</div>
					</div>
					<div class="row">
						<div class="col-12">
							<div class="home__search">
								<h1>
									#ВИБОРИ2021
								</h1>
								<div
									class="home__search-input d-flex justify-content-between"
								>
									<svg class="icon">
										<use href="#icon_search"></use>
									</svg>
									<input
										type="text"
										placeholder="Введіть ім'я, партію чи округ..."
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</header>
		<main>
			<div class="container">
				<div class="row" v-if="fetchError">
					<div class="col-12">
						<div class="error__loading text-center">
							<h3>
								Помилка завантаження
							</h3>
							<div class="cta">
								<button
									class="btn btn-primary"
									@click="getCandidates"
								>
									Спробувати ще раз
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="row" v-show="!fetchError">
					<div class="col-12">
						<h3 class="mt-4">
							<strong>
								Кандидати
							</strong>
						</h3>
						<div class="home__tabs">
							<ul class="nav nav-tabs" id="myTab" role="tablist">
								<li class="nav-item">
									<a
										class="nav-link active"
										id="home-tab"
										data-toggle="tab"
										href="#home"
										role="tab"
										aria-controls="home"
										aria-selected="true"
										>Усі</a
									>
								</li>
								<li class="nav-item">
									<a
										class="nav-link"
										id="profile-tab"
										data-toggle="tab"
										href="#profile"
										role="tab"
										aria-controls="profile"
										aria-selected="false"
										>За регіоном</a
									>
								</li>
							</ul>
							<div class="tab-content" id="myTabContent">
								<div
									class="tab-pane fade show active"
									id="home"
									role="tabpanel"
									aria-labelledby="home-tab"
								>
									<div class="row">
										<div
											class="col-12 col-sm-4 col-lg-3"
											v-for="n in 8"
											:key="n"
											v-show="isLoading"
										>
											<content-loader
												width="200"
												height="200"
												:speed="2"
												primaryColor="#f3f3f3"
												secondaryColor="#e7e7e7"
											>
												<circle
													cx="100"
													cy="80"
													r="60"
												/>
												<rect
													height="10"
													width="120"
													rx="2"
													ry="2"
													x="40"
													y="150"
												></rect>
												<rect
													height="6"
													width="160"
													rx="2"
													ry="2"
													x="20"
													y="165"
												></rect>
												<rect
													height="8"
													width="40"
													rx="2"
													ry="2"
													x="80"
													y="185"
												></rect>
											</content-loader>
										</div>
										<div
											v-for="(c, i) in candidates"
											:key="c.slug + i"
											class="cand col-12 col-sm-4 col-lg-3"
										>
											<router-link
												:to="{
													name: 'userSite',
													params: { slug: c.slug },
												}"
											>
												<div
													class="cand__thumb"
													:style="{
														backgroundImage:
															'url(' +
															c.hero.photo +
															')',
													}"
												></div>
												<div class="cand__info">
													<h4>
														{{ c.hero.name }}
													</h4>
													<p>
														м.Трускавець, Львівська
														область
													</p>
													<div
														class="district"
														v-if="c.hero.district"
													>
														Округ №
														{{ c.hero.district }}
													</div>
												</div>
											</router-link>
										</div>
									</div>
								</div>
								<div
									class="tab-pane fade"
									id="profile"
									role="tabpanel"
									aria-labelledby="profile-tab"
								>
									...
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
	data() {
		return {
			fetchError: false,
			api_base: "https://us-central1-winwin-43992.cloudfunctions.net/api",
			candidates: [],
			isLoading: false,
		};
	},
	components: {
		ContentLoader,
	},
	methods: {
		getCandidates() {
			this.isLoading = true;
			this.fetchError = false;
			var url = this.api_base + "/site/getAll/";
			fetch(url, {
				cache: "no-cache",
				headers: {
					"Access-Control-Allow-Origin": "*",
				},
			})
				.then((response) => {
					return response.json();
				})
				.then((data) => {
					if (data.items) {
						this.candidates = [];
						data.items.forEach((item) => {
							this.candidates.push(item);
						});
						this.isLoading = false;
					} else {
						this.candidates = [];
					}
				})
				.catch((error) => {
					console.log(error);
					this.isLoading = false;
					this.fetchError = true;
				});
		},
	},
	async beforeMount() {
		await this.getCandidates();
	},
};
</script>

<style lang="scss" scoped>
@import "../scss/bootstrap.min.css";
@import "../scss/_buttons.scss";
@import "../scss/_typography.scss";
h1 {
	font-weight: bold;
	color: lighten(#171b26, 20%);
	font-style: italic;
}
.header-home {
	&__background {
		padding: 60px 0px 20px;
		// background-image: url("~@/assets/img/home_bg.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		-webkit-animation: animateGradient 12s ease infinite;
		animation: animateGradient 12s ease infinite;
		background-size: 150vw 150vh;
		background: #d1e6fe;
	}
}
.home {
	&__tabs {
		margin-top: 40px;
	}
	&__search {
		margin-bottom: 20px;
		&-input {
			position: relative;

			svg {
				position: absolute;
				top: 50%;
				left: 15px;
				transform: translateY(-50%);
			}
			input {
				border: none;
				padding: 0px 30px 0px 48px;
				height: 48px;
				flex-grow: 3;
				margin-right: 10px;
				outline: none;
				border-radius: 8px;
				box-shadow: 0px 5px 15px 0px rgba(#000, 0.1);
			}
		}
	}
}
.cand {
	a {
		padding: 15px;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #000;
		text-decoration: none;
		text-align: center;
		&:hover {
		}
	}
	&__thumb {
		width: 160px;
		height: 160px;
		background-size: cover;
		background-position: center;
		background-color: #e7e7e7;
		border-radius: 50%;
		box-shadow: 0px 0px 0px 3px #3eb253;
		margin-bottom: 20px;
	}
	&__info {
		h4 {
			font-size: 18px;
			text-align: center;
			font-weight: 700;
			margin-bottom: 5px !important;
		}
		p {
			margin-bottom: 10px;
			font-size: 14px;
			color: #666;
			font-weight: 300;
		}
		.district {
			font-size: 12px;
			font-weight: 600;
			color: #3eb253;
		}
	}
}
.error {
	&__loading {
		padding: 60px 0px;
	}
}
@-webkit-keyframes animateGradient {
	0% {
		background-position: 0% 42%;
	}
	50% {
		background-position: 100% 59%;
	}
	100% {
		background-position: 0% 42%;
	}
}
@keyframes animateGradient {
	0% {
		background-position: 0% 42%;
	}
	50% {
		background-position: 100% 59%;
	}
	100% {
		background-position: 0% 42%;
	}
}
</style>
